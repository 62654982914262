
import Task from "@/components/task/Task.vue";
import { useTaskStore } from "@/stores/taskStore";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TaskListComponent",
  components: {
    Task,
  },
  methods: {},
  computed: {
    ...mapStores(useTaskStore),
  },
});
