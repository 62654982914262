import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "task-name-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "task-name"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskEdit = _resolveComponent("TaskEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: "task-card",
    draggable: "true",
    onDragstart: _cache[5] || (_cache[5] = (ev) => _ctx.setDragging(true, ev)),
    onDragend: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setDragging(false)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.editMode)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.copiedTask.name), 1))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            id: "task-name-edit",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.copiedTask.name) = $event))
          }, null, 512)), [
            [_vModelText, _ctx.copiedTask.name]
          ])
    ]),
    (!_ctx.isDragged)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_TaskEdit, {
            onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editTask())),
            onAbort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.abortEdit())),
            onConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateTask())),
            onDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteTask(_ctx.task.id)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 32))
}