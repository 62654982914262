
import Footer from "@/components/footer/Footer.vue";
import { defineComponent } from "vue";
import AddTask from "./components/add-task/AddTask.vue";
import TaskList from "./components/task-list/TaskList.vue";

export default defineComponent({
  components: {
    TaskList,
    Footer,
    AddTask,
  },
});
