
import { TaskModel } from "@/models/Task";
import { useTaskStore } from "@/stores/taskStore";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";
import TaskEdit from "../task-edit/TaskEdit.vue";

export default defineComponent({
  props: {
    task: {
      type: Object as PropType<TaskModel>,
      required: true,
    },
  },
  data() {
    return {
      copiedTask: {
        name: this.task.name,
        id: this.task.id,
        priority: this.task.priority,
      } as TaskModel,
      isDragged: false,
      editMode: false,
    };
  },
  methods: {
    setDragging(dragging: boolean, ev?: DragEvent): void {
      this.isDragged = dragging;
      if (ev != null && ev.dataTransfer != null) {
        ev.dataTransfer.dropEffect = "move";
        ev.dataTransfer.effectAllowed = "move";
        ev.dataTransfer.setData("task", JSON.stringify(this.copiedTask));
      }
    },
    deleteTask(taskId: string) {
      if (confirm("Willst du diese Aufgabe wirklich löschen?")) {
        this.taskStore.deleteTask(taskId);
      }
    },
    editTask(): void {
      this.editMode = !this.editMode;
    },
    abortEdit(): void {
      this.copiedTask = {
        name: this.task.name,
        id: this.task.id,
        priority: this.task.priority,
      };
      this.editTask();
    },
    updateTask(): void {
      this.editTask();
      this.taskStore.updateTask(this.copiedTask);
    },
  },
  computed: {
    ...mapStores(useTaskStore),
  },
  components: { TaskEdit },
});
