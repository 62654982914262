
import { Priority } from "@/models/Priority.enum";
import { TaskModel } from "@/models/Task";
import { mapStores } from "pinia";
import { defineComponent } from "vue";
import { useTaskStore } from "../../stores/taskStore";
import BoardTask from "../board-task/BoardTask.vue";

export default defineComponent({
  name: "MatrixComponent",
  data() {
    return {
      Priority: Priority,
    };
  },
  methods: {
    onDrop(payload: DragEvent) {
      if (payload.dataTransfer != null) {
        const data = payload.dataTransfer.getData("task");
        if (data != null) {
          const task = JSON.parse(data) as TaskModel;
          const taskDropZone = (payload.target as HTMLElement).closest("div");

          if (!taskDropZone?.id.includes("area")) return;

          if (taskDropZone != null) {
            const dropZoneId = taskDropZone.id;

            const priorityDict: { [name: string]: Priority } = {
              A: Priority.A,
              B: Priority.B,
              C: Priority.C,
              D: Priority.D,
            };

            const priority = priorityDict[dropZoneId.toUpperCase()[0]];
            if (this.taskStore.getTasksForPriority(priority).length >= 9) {
              alert(
                "Du kannst diese Aufgabe nicht hier ablegen, da das Limit von 9 Aufgaben für diese Priorität bereits erreicht wurde. Arbeite zunächst die Aufgaben dieser Priorität ab."
              );
              return;
            }

            task.priority = priority;
            this.taskStore.updateTask(task);
          }
        }
      }
    },
  },
  computed: {
    ...mapStores(useTaskStore),
  },
  components: { BoardTask },
});
