
import { Priority } from "@/models/Priority.enum";
import { TaskModel } from "@/models/Task";
import { useTaskStore } from "@/stores/taskStore";
import { mapStores } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddTaskComponent",
  methods: {
    addTask(): void {
      this.taskStore.addTask({
        id: uuidv4(),
        name: this.task.name,
        priority: this.task.priority,
      });
      const input = document.getElementById("task-name");
      (input as HTMLInputElement).value = "";
    },
  },
  data() {
    return {
      task: {
        name: "",
        priority: undefined,
      } as TaskModel,
      Priority: Priority,
    };
  },
  computed: {
    ...mapStores(useTaskStore),
  },
});
