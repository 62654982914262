
import Matrix from "@/components/matrix/Matrix.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Matrix,
  },
});
