
import { defineComponent } from "vue";

export default defineComponent({
  name: "TaskEditComponent",
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    edit() {
      this.editMode = !this.editMode;
    },
    confirmUpdate() {
      this.edit();
      this.$emit("confirm");
    },
    abortUpdate() {
      this.edit();
      this.$emit("abort");
    },
  },
  emits: ["edit", "abort", "confirm", "delete"],
});
